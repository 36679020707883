<template>
  <v-container class="my-3">
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not update project. Try again later") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not update project. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackErrorFetchDetails"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not get project details. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackErrorFetchDetails = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <div v-if="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
    <v-row justify="space-between" v-else>
      <v-row justify="space-between" class="mb-1">
        <v-col cols="12" md="6">
          <h2 class="headline text-capitalize">
            {{ $t("update project") }}
          </h2>
        </v-col>

        <v-col cols="12" md="6" align-self="end" class="text-right">
          <v-btn
            small
            dark
            :loading="loading"
            :color="$store.state.secondaryColor"
            @click="uploadData"
          >
            <v-icon small left>save</v-icon>
            <span class="caption text-capitalize">{{ $t("update") }}</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-alert>
        <v-form ref="form">
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm6 md4 lg4 class="caption px-1">
                <span class="font-weight-medium"
                  >{{ $t("project title") }}:
                </span>
                <v-text-field
                  outlined
                  dense
                  v-model="project.projectTitle"
                  :rules="rules.emptyField"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4 lg4 class="caption px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("assigned to") }}:
                </span>
                <v-autocomplete
                  outlined
                  dense
                  item-text="names.first"
                  item-value="id"
                  :items="employeesList"
                  v-model="project.assignedTo"
                  @change="setEmployeeId(project.assignedTo)"
                  :rules="rules.emptyField"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6 md4 lg4 dense class="caption px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("business") }}:
                </span>
                <v-text-field
                  v-if="selectedBusiness"
                  outlined
                  dense
                  v-model="selectedBusinessName"
                  readonly
                  :rules="rules.emptyField"
                ></v-text-field>

                <v-select
                  v-else
                  outlined
                  dense
                  item-text="businessName"
                  item-value="id"
                  :items="ownersBusinesses"
                  v-model="project.businessId"
                  :rules="rules.emptyField"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6 lg6 class="caption px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("project status") }}:
                </span>
                <v-select
                  outlined
                  dense
                  item-text="project status"
                  :items="statuses"
                  item-value="id"
                  v-model="project.status"
                  :rules="rules.emptyField"
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6 lg6 class="caption px-1">
                <span class="font-weight-medium">{{ $t("due date") }}: </span>
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="project.dueDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="project.dueDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      in
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="project.dueDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(project.dueDate)"
                    >
                      {{ $t("ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 class="caption px-1">
                <span class="font-weight-medium text-capitalize">
                  {{ $t("project description") }}:
                </span>
                <v-textarea
                  counter
                  outlined
                  v-model="project.description"
                  :rules="rules.lengthLimit"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-alert>
    </v-row>
  </v-container>
</template>

<script>
import { format } from "date-fns";
import PleaseWait from "@/components/templates/PleaseWait";
import db from "@/plugins/fb";
export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    snackSuccess: false,
    snackError: false,
    snackErrorFetchDetails: false,
    modal: false,
    employeesList: [],
    timeout: 2000,
    pleaseWait: true,
    loading: false,
    project: {
      projectTitle: "",
      businessId: "",
      description: "",
      dueDate: "",
      status: "",
    },
  }),

  computed: {
    selectedBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessesId() {
      return this.$store.getters.getBusinessId;
    },

    selectedBusinessName() {
      let busId = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      let busName = busId.businessName;
      return busName;
    },

    rules() {
      return {
        emptyField: [(v) => !!v || this.$t("this field is required")],
        number: [
          (v) => !!v || this.$t("this field is required"),
          (v) => !isNaN(v) || this.$t("please enter a number"),
        ],
      };
    },
    statuses() {
      return ["done", "overdue", "inProgress"];
    },
  },
  created() {
    this.getProjectDetails();
    this.getAllEmployees();
  },
  methods: {
    getProjectDetails() {
      db.collection("projects")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.project = doc.data();

          this.project.dueDate = this.project.dueDate.toDate();
          this.pleaseWait = false;
        })
        .catch(() => {
          this.snackErrorFetchDetails = true;
        });
    },
    getAllEmployees() {
      this.employeesList = [];
      if (this.selected) {
        db.collection("employees")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.employeesList.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            this.loading = false;
          });
      } else {
        db.collection("employees")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.employeesList.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
          });
      }
    },
    uploadData() {
      this.loading = true;
      db.collection("projects")
        .doc(this.$route.params.id)
        .update(this.project)
        .then(() => {
          this.loading = false;
          this.snackSuccess = true;
          setTimeout(() => this.goBack(), 2000);
        })
        .catch(() => {
          this.loading = false;
          this.snackError = true;
        });
    },

    digits(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 43) {
        // 46 is dot 43 is +
        event.preventDefault();
      }
    },

    setEmployeeId(id) {
      this.project.assignedTo = id;
    },

    formatDate(val) {
      var date = val.toDate();
      return format(date, "yyyy-MM-dd");
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
